import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Container } from "@mui/material";
import { SearchParamsApp } from "../../lib/constants";
import carRentStructure from "./utils";
import CarCard from "./Components/CarCard";
import RentForm from "./Components/RentForm";

const CarRent = () => {
  const [searchParams] = useSearchParams();
  const app = searchParams.get("app");
  const { pathname } = useLocation();
  const marginTop = app === SearchParamsApp ? 20 : 120;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "calc(100vh - 100px)",
        pt: `${marginTop}px`,
      }}
    >
      {carRentStructure.map((data, i) => (
        <CarCard key={i} data={data} />
      ))}
      <RentForm />
    </Container>
  );
};

export default CarRent;
