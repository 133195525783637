import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

const TableHeaderStyle = {
  position: "sticky",
  left: 0,
  background: "#fff",
  fontFamily: "Montserrat",
  fontSize: { xs: 12, sm: 16, md: 20 },
  fontWeight: 700,
  whiteSpace: "nowrap",
  px: { xs: 1, sm: 2 },
};

const TableCellStyle = {
  fontFamily: "Montserrat",
  fontSize: { xs: 12, sm: 16, md: 20 },
  fontWeight: 500,
  color: "#3e4958",
  whiteSpace: "nowrap",
  px: { xs: 1, sm: 2 },
};

const PriceTable = ({ data }) => {
  const { t } = useTranslation(["carRent", "common"]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: "#e9e9e9 solid 1px",
        width: {
          xs: "calc(75vw + 1.66vw)",
          sm: "calc(460px * 1)",
          md: "calc(460px * 1.5)",
          lg: "calc(460px * 2)",
        },
        mb: { xs: 2, md: 5 },
        mt: { xs: 2, md: 0 },
      }}
    >
      <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
        <TableBody>
          {data.map((row, i) => (
            <TableRow
              key={i}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {row.map((text, i) => {
                if (text === "days" || text === "price") {
                  return (
                    <TableCell
                      key={i}
                      component="th"
                      scope="row"
                      sx={TableHeaderStyle}
                    >
                      {t(text)}
                    </TableCell>
                  );
                }

                return (
                  <TableCell key={i} align="center" sx={TableCellStyle}>
                    {isNaN(text) ? t(text) : text + "€"}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceTable;
