import { useRef, useCallback } from "react";
import { Box, Dialog, DialogContent } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

const ImageModalView = ({
  open,
  handleToggle,
  LeftArrow,
  data,
  RightArrow,
  imageNumber,
  CircleButtons,
}) => {
  const touchPadCoordsStartRef = useRef(0);

  const handleLeftClick = useCallback(() => {
    LeftArrow();
    handleToggle();
  }, [LeftArrow, handleToggle]);
  
  const handleRightClick = useCallback(() => {
    RightArrow();
    handleToggle();
  }, [RightArrow, handleToggle]);
  
  return (
    <Dialog
      onKeyDown={(e) => {
        if (e.code === "ArrowRight") RightArrow();
        if (e.code === "ArrowLeft") LeftArrow();
      }}
      fullScreen
      open={open}
      onClose={handleToggle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialog-paper": {
          background: "rgba(0,0,0,1)",
        },
      }}
    >
      <DialogContent
        onClick={handleToggle}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 0,
        }}
      >
        <Box
          sx={{
            transition: "ease-in-out 0.15s",
            position: "absolute",
            top: 20,
            right: 20,
            color: "#fff",
            "&:hover": { cursor: "pointer", color: "#999" },
          }}
        >
          <CloseRoundedIcon sx={{ fontSize: { xs: "6vw", sm: 36 } }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              onClick={handleLeftClick}
              sx={{
                px: { xs: 0, sm: 0.5, md: 2, lg: 3 },
                py: { xs: 0, sm: 3, md: 6, lg: 9 },
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                transition: "ease-in-out 0.15s",
                color: "#fff",
                "&:hover": { cursor: "pointer", color: "#999" },
              }}
            >
              <ArrowBackIosNewRoundedIcon
                sx={{ fontSize: { xs: "4vw", sm: 36 } }}
              />
            </Box>
            <Box
              id={`carRent-${data.id}`}
              onTouchStart={(e) => {
                touchPadCoordsStartRef.current = e.changedTouches[0].clientX;
              }}
              onTouchEnd={(e) => {
                if (touchPadCoordsStartRef.current < e.changedTouches[0].clientX - 75) {
                  LeftArrow();
                } else if (
                  touchPadCoordsStartRef.current - 75 >
                  e.changedTouches[0].clientX
                ) {
                  RightArrow();
                }
                touchPadCoordsStartRef.current = 0;
              }}
              sx={{
                position: "relative",
                height: {
                  xs: "calc(100vw / 1.5)",
                  sm: "calc(55vw + 1.14vw)",
                },
                width: { xs: "100vw", sm: "calc(82.5vw + 1.66vw)" },
                maxHeight: "calc(307px * 2 * 1.45)",
                maxWidth: "calc(460px * 2 * 1.45)",
              }}
            >
              {data.images.map((image, i) => {
                return (
                  <img
                    style={{
                      position: "absolute",
                      opacity: imageNumber === i ? 1 : 0,
                      transition: "ease-in-out 0.5s",
                      cursor: "pointer",
                    }}
                    key={i}
                    src={image}
                    alt="Auto"
                    height={"100%"}
                    width={"100%"}
                  />
                );
              })}
            </Box>

            <Box
              onClick={handleRightClick}
              sx={{
                px: { xs: 0, sm: 0.5, md: 2, lg: 3 },
                py: { xs: 0, sm: 3, md: 6, lg: 9 },
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                transition: "ease-in-out 0.15s",
                color: "#fff",
                "&:hover": { cursor: "pointer", color: "#999" },
              }}
            >
              <ArrowForwardIosRoundedIcon
                sx={{ fontSize: { xs: "4vw", sm: 36 } }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: { xs: "1.75vw", sm: 1 },
            }}
          >
            {data.images.map((image, i) => (
              <Box
                onClick={() => {
                  CircleButtons(i);
                  handleToggle();
                }}
                key={image + i}
                sx={{
                  width: { xs: "2.5vw", sm: "10px" },
                  height: { xs: "2.5vw", sm: "10px" },
                  borderRadius: "50%",
                  background: imageNumber === i ? "#fff" : "#666",
                  mr: { xs: "1.75vw", sm: 1 },
                  "&:hover": { cursor: "pointer" },
                }}
              ></Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageModalView;
