import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, TextField } from "@mui/material";

const Calculator = ({ data }) => {
  const { t } = useTranslation(["carRent", "common"]);
  const [days, setDays] = useState("");
  const [totalPrice, setTotalPrice] = useState("");

  const findPriceIndex = (value) => {
    if ((+value > 0 && +value < 1000) || value === "") {
      setDays(value);
      if (+value < 3 && value) {
        setTotalPrice(+value * data[1][1]);
      } else if (+value > 2 && +value < 8) {
        setTotalPrice(+value * data[1][2]);
      } else if (+value > 7 && +value < 21) {
        setTotalPrice(+value * data[1][3]);
      } else if (+value > 20 && +value < 31) {
        setTotalPrice(+value * data[1][4]);
      } else if (+value > 30) {
        setTotalPrice(t("negotiable"));
      } else {
        setTotalPrice("");
      }
    }
  };

  return (
    <Box
      sx={{
        width: {
          xs: "calc(75vw + 1.66vw)",
          sm: "calc(460px * 1)",
          md: "calc(460px * 1.5)",
          lg: "calc(460px * 2)",
        },
        display: "flex",
        alignItems: "center",
        borderTop: "#c4c4c4 solid 1px",
        borderBottom: "#c4c4c4 solid 1px",
        py: 2,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: { xs: 12, sm: 16, md: 20 },
          fontWeight: 700,
          mr: { xs: 1, sm: 2 },
        }}
      >
        {t("calculate_rental_cost")}:
      </Typography>
      <TextField
        value={days}
        onChange={(e) => {
          findPriceIndex(e.target.value);
        }}
        variant="outlined"
        size="small"
        placeholder={t("days")}
        sx={{
          width: "80px",
          mr: { xs: 1, sm: 2 },
          "& .MuiOutlinedInput-input": {
            fontSize: { xs: "10px", sm: "16px" },
            px: { xs: 1, sm: 1.5 },
          },
        }}
      />
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: { xs: 12, sm: 16, md: 20 },
          fontWeight: 700,
          mr: { xs: 1, sm: 2 },
        }}
      >
        =
      </Typography>
      <TextField
        value={totalPrice}
        variant="outlined"
        size="small"
        placeholder={t("price")}
        sx={{
          width: "120px",
          mr: { xs: 0.5, sm: 1 },
          fontSize: "10px",
          "& .MuiOutlinedInput-input": {
            fontSize: { xs: "10px", sm: "16px" },
            px: { xs: 1, sm: 1.5 },
          },
        }}
      />
      <Typography
        sx={{
          fontFamily: "Montserrat",
          fontSize: { xs: 12, sm: 16, md: 20 },
          fontWeight: 700,
        }}
      >
        €
      </Typography>
    </Box>
  );
};

export default Calculator;
