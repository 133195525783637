import { useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { SearchParamsApp } from "../../../lib/constants";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import LocalGasStationRoundedIcon from "@mui/icons-material/LocalGasStationRounded";
import MiscellaneousServicesRoundedIcon from "@mui/icons-material/MiscellaneousServicesRounded";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import CarDoor from "../../../Icons/car_door";
import CarEngine from "../../../Icons/car_engine";

const CarCard = ({ data }) => {
  const touchPadCoordsStartRef = useRef(0);
  const [searchParams] = useSearchParams();
  const app = searchParams.get("app");
  const { t } = useTranslation(["carRent", "common"]);
  const [imageNumber, setImageNumber] = useState(0);
  const detailsLink =
    app === SearchParamsApp
      ? `/car_rent/details?app=client&carId=${data.id}`
      : `/car_rent/details?carId=${data.id}`;

  const LeftArrow = () => {
    if (imageNumber <= 0) {
      setImageNumber(data.images.length - 1);
    } else {
      setImageNumber((prev) => prev - 1);
    }
  };

  const RightArrow = () => {
    if (imageNumber >= data.images.length - 1) {
      setImageNumber(0);
    } else {
      setImageNumber((prev) => prev + 1);
    }
  };

  const CircleButtons = (index) => {
    setImageNumber(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          onClick={LeftArrow}
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "ease-in-out 0.15s",
            "&:hover": { cursor: "pointer", color: "#999" },
          }}
        >
          <ArrowBackIosNewRoundedIcon
            sx={{ fontSize: { xs: "4vw", sm: 24 } }}
          />
        </Box>
        <Box
          onTouchStart={(e) => {
            touchPadCoordsStartRef.current = e.changedTouches[0].clientX;
          }}
          onTouchEnd={(e) => {
            if (
              touchPadCoordsStartRef.current <
              e.changedTouches[0].clientX - 75
            ) {
              LeftArrow();
            } else if (
              touchPadCoordsStartRef.current - 75 >
              e.changedTouches[0].clientX
            ) {
              RightArrow();
            }
            touchPadCoordsStartRef.current = 0;
          }}
          sx={{
            position: "relative",
            mx: { xs: "2vw", sm: 3 },
            height: {
              xs: "calc(50vw + 1.14vw)",
              sm: "calc(307px * 1)",
              md: "calc(307px * 1.5)",
              lg: "calc(307px * 2)",
            },
            width: {
              xs: "calc(75vw + 1.66vw)",
              sm: "calc(460px * 1)",
              md: "calc(460px * 1.5)",
              lg: "calc(460px * 2)",
            },
          }}
        >
          <Box
            id={`carRent-${data.id}`}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: data.available ? 0 : 1,
              opacity: data.available ? 1 : 0.85,
              position: "absolute",
              background: "#000",
              height: {
                xs: "calc(50vw + 1.14vw)",
                sm: "calc(307px * 1)",
                md: "calc(307px * 1.5)",
                lg: "calc(307px * 2)",
              },
              width: {
                xs: "calc(75vw + 1.66vw)",
                sm: "calc(460px * 1)",
                md: "calc(460px * 1.5)",
                lg: "calc(460px * 2)",
              },
            }}
          >
            <Typography
              sx={{
                color: data.available ? "#000" : "#fff",
                fontFamily: "Montserrat",
                fontSize: { xs: 14, sm: 18, md: 24 },
                fontWeight: 600,
              }}
            >
              СКОРО! {/* Перевод */}
            </Typography>
          </Box>
          <NavLink to={detailsLink}>
            {data.images.map((image, i) => {
              return (
                <img
                  style={{
                    position: "absolute",
                    opacity: imageNumber === i ? 1 : 0,
                    transition: "ease-in-out 0.5s",
                  }}
                  key={i}
                  src={image}
                  alt="Auto"
                  height={"100%"}
                  width={"100%"}
                />
              );
            })}
          </NavLink>
        </Box>

        <Box
          onClick={RightArrow}
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "ease-in-out 0.15s",
            "&:hover": { cursor: "pointer", color: "#999" },
          }}
        >
          <ArrowForwardIosRoundedIcon
            sx={{ fontSize: { xs: "4vw", sm: 24 } }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xs: "1.5vw", sm: 1 },
        }}
      >
        {data.images.map((image, i) => (
          <Box
            onClick={() => CircleButtons(i)}
            key={image + i}
            sx={{
              width: { xs: "2vw", sm: "10px" },
              height: { xs: "2vw", sm: "10px" },
              borderRadius: "50%",
              background: imageNumber === i ? "#000" : "#666",
              mr: { xs: "1.5vw", sm: 1 },
              "&:hover": { cursor: "pointer" },
            }}
          ></Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 1,
          width: {
            xs: "calc(75vw + 1.66vw)",
            sm: "calc(460px * 1)",
            md: "calc(460px * 1.5)",
            lg: "calc(460px * 2)",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: { xs: 16, sm: 20, md: 24 },
            fontWeight: 700,
          }}
        >
          {data.name} {data.year}
        </Typography>
        <Box sx={{ display: "flex", ml: 2 }}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "end",
              whiteSpace: "nowrap",
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              mr: { xs: 0.5, sm: 1 },
              pb: { xs: 0.2, sm: 0.2, md: 0.4 },
            }}
          >
            {t("from")}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "end",
              whiteSpace: "nowrap",
              fontFamily: "Montserrat",
              fontSize: { xs: 16, sm: 20, md: 24 },
              fontWeight: 700,
            }}
          >
            {data.price}€
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "end",
              whiteSpace: "nowrap",
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              pb: { xs: 0.2, sm: 0.2, md: 0.4 },
            }}
          >
            /{t("day")}
          </Typography>
        </Box>
      </Box>
      <Box
        component="ul"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          mt: 0.5,
          width: {
            xs: "calc(75vw + 1.66vw)",
            sm: "calc(460px * 1)",
            md: "calc(460px * 1.5)",
            lg: "calc(460px * 2)",
          },
          mb: { xs: 2, sm: 5 },
          p: 0,
        }}
      >
        <Box
          component="li"
          sx={{
            display: "flex",
            alignItems: "center",
            mr: { xs: 1, sm: 2 },
            mt: 0.5,
          }}
        >
          <Box
            sx={{
              width: { xs: 16, sm: 20, md: 24 },
              height: { xs: 16, sm: 20, md: 24 },
            }}
          >
            <CarDoor />
          </Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              ml: { xs: 0.5, sm: 1 },
              color: "#3e4958",
              whiteSpace: "nowrap",
            }}
          >
            {data.doors}
          </Typography>
        </Box>

        <Box
          component="li"
          sx={{
            display: "flex",
            alignItems: "center",
            mr: { xs: 1, sm: 2 },
            mt: 0.5,
          }}
        >
          <Box
            sx={{
              width: { xs: 16, sm: 20, md: 24 },
              height: { xs: 16, sm: 20, md: 24 },
            }}
          >
            <CarEngine />
          </Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              ml: { xs: 0.5, sm: 1 },
              color: "#3e4958",
              whiteSpace: "nowrap",
            }}
          >
            {data.engineVolume}
            {t("L")}
          </Typography>
        </Box>

        {data.conditioner.flag && (
          <Box
            component="li"
            sx={{
              display: "flex",
              alignItems: "center",
              mr: { xs: 1, sm: 2 },
              mt: 0.5,
            }}
          >
            <AcUnitRoundedIcon sx={{ fontSize: { xs: 16, sm: 20, md: 24 } }} />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: 12, sm: 14, md: 16 },
                fontWeight: 600,
                ml: { xs: 0.5, sm: 1 },
                color: "#3e4958",
                whiteSpace: "nowrap",
              }}
            >
              {t(data.conditioner.text)}
            </Typography>
          </Box>
        )}

        <Box
          component="li"
          sx={{
            display: "flex",
            alignItems: "center",
            mr: { xs: 1, sm: 2 },
            mt: 0.5,
          }}
        >
          <MiscellaneousServicesRoundedIcon
            sx={{ fontSize: { xs: 16, sm: 20, md: 24 } }}
          />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              ml: { xs: 0.5, sm: 1 },
              color: "#3e4958",
              whiteSpace: "nowrap",
            }}
          >
            {t(data.gearbox)}
          </Typography>
        </Box>

        <Box
          component="li"
          sx={{
            display: "flex",
            alignItems: "center",
            mr: { xs: 1, sm: 2 },
            mt: 0.5,
          }}
        >
          <LocalGasStationRoundedIcon
            sx={{ fontSize: { xs: 16, sm: 20, md: 24 } }}
          />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              ml: { xs: 0.5, sm: 1 },
              color: "#3e4958",
              whiteSpace: "nowrap",
            }}
          >
            {t(data.fuelType)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CarCard;
