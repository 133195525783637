import { NavLink } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Typography } from "@mui/material";
import RentCarDetails from "./Components/RentCarDetails";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { SearchParamsApp } from "../../lib/constants";
import "./style.css";

const CarRentDetails = () => {
  const [searchParams] = useSearchParams();
  const app = searchParams.get("app");
  const { t } = useTranslation(["carRent", "common"]);
  const backLink =
    app === SearchParamsApp ? "/car_rent?app=client" : "/car_rent";
  const marginTop = app === SearchParamsApp ? 20 : 120;

  return (
    <Container sx={{ minHeight: "calc(100vh - 100px)", pt: `${marginTop}px` }}>
      <NavLink
        to={backLink}
        className={"BackButton"}
      >
        <ArrowBackRoundedIcon sx={{ fontSize: { xs: 24, sm: 28, md: 32 } }} />
        <Typography
          sx={{
            ml: 1,
            fontFamily: "Montserrat",
            fontSize: { xs: 16, sm: 20, md: 24 },
            fontWeight: 600,
            textDecoration: "none",
          }}
        >
          {t("common:back")}
        </Typography>
      </NavLink>
      <RentCarDetails />
    </Container>
  );
};

export default CarRentDetails;
