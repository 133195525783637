import React from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import Loading from "./components/Loading";
import { Suspense } from "react";
import Footer from "./components/Footer";
import AppRoutes from "./Routes";
import { SnackbarProvider } from "notistack";
import { SearchParamsApp } from "./lib/constants";

function App() {
  const [searchParams] = useSearchParams();
  const app = searchParams.get("app");
  return (
    <div className="App">
      {app !== SearchParamsApp && <Header />}
      <Suspense fallback={<Loading />}>
        <SnackbarProvider maxSnack={2} autoHideDuration={1500}>
          <Routes>
            {AppRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </SnackbarProvider>
      </Suspense>
      {app !== SearchParamsApp && <Footer />}
    </div>
  );
}

export default App;
