const CarDoor = () => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"     
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9162 2.74219H15.8242C15.174 2.74493 14.5513 3.00547 14.0929 3.46666L4.994 12.5655C4.53281 13.024 4.27227 13.6466 4.26953 14.2969V24.8447C4.26953 25.4961 4.52827 26.1207 4.98883 26.5813C5.44939 27.0418 6.07404 27.3006 6.72537 27.3006H23.9162C24.5676 27.3006 25.1922 27.0418 25.6528 26.5813C26.1133 26.1207 26.3721 25.4961 26.3721 24.8447V5.19802C26.3721 4.5467 26.1133 3.92204 25.6528 3.46149C25.1922 3.00093 24.5676 2.74219 23.9162 2.74219ZM23.9162 12.5655H8.45673L15.8242 5.19802H23.9162V12.5655Z"
        fill="black"
      />
      <path
        d="M22.6929 17.4773H19.0092C18.6835 17.4773 18.3712 17.348 18.1409 17.1177C17.9106 16.8874 17.7812 16.5751 17.7812 16.2494C17.7812 15.9237 17.9106 15.6114 18.1409 15.3811C18.3712 15.1509 18.6835 15.0215 19.0092 15.0215H22.6929C23.0186 15.0215 23.3309 15.1509 23.5612 15.3811C23.7915 15.6114 23.9208 15.9237 23.9208 16.2494C23.9208 16.5751 23.7915 16.8874 23.5612 17.1177C23.3309 17.348 23.0186 17.4773 22.6929 17.4773Z"
        fill="white"
      />
    </svg>
  );
};

export default CarDoor;
