import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Phones from "../../images/Main/Pixel-True-Mockup-8.png";
import { useTheme } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import GooglePlayButton from "../../components/buttons/GooglePlayButton";
import AppStoreButton from "../../components/buttons/AppStoreButton";
import LetsStart from "../../components/buttons/LetsStart";
import Join from "../../components/buttons/Join";
import DownloadAppDriver from "../../components/buttons/DownloadAppDriver";
import VisaPromo from "../../Icons/visa_promo";
import UndrawCityDriver from "../../Icons/undraw_city_driver";
import LocationPin from "../../Icons/location_pin";
// import VaraVara from "../../Icons/vara_vara";
// import VaraVara from "../../images/Main/VARA_VARA.png";
import "../../styles/App.css";
// import { borderBottom } from "@mui/system";

function Main() {
  const { t } = useTranslation(["main", "common"]);
  const theme = useTheme();
  const { pathname } = useLocation();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // if (true) {
  //   return (
  //     <>
  //       <Container
  //         id="Main"
  //         aria-hidden="true"
  //         sx={{
  //           display: "flex",
  //           justifyContent: "center",
  //           minHeight: "calc( 100vh - 100px)",
  //           width: "100%",
  //           pt: 8,
  //         }}
  //       >
  //         {/* <Box
  //           sx={{
  //             width: "100%",
  //             display: "flex",
  //             flexDirection: "column",
  //             justifyContent: "center",
  //             // pt: { xs: "50px", md: "75px", lg: "100px" },
  //             border: "black solid 1px",
  //           }}
  //         > */}
  //         {/*-----------------------------------------------DOWNLOAD APP LETZ-------------------------------------------------------------------------------------------*/}
  //         <Box
  //           sx={{
  //             width: {xs: "100%", sm: "calc((723px / 100) * 80)", md: "100%" },
  //             display: "flex",
  //             flexDirection: { xs: "column", md: "row" },
  //             justifyContent: "space-between",
  //             // alignSelf: "center",
  //             height: {xs: "100%", md: "400px", lg: "500px" },
  //             mt: { xs: "50px", md: "75px", lg: "100px" },
  //             // border: "black solid 1px",
  //           }}
  //         >
  //           <Box
  //             sx={{
  //               // height: { md: "420px", lg: "520px" },
  //               width: { sm: "100%", md: "50%", lg: "50%" },
  //               display: "flex",
  //               justifyContent: "center",
  //               // border: "black solid 1px",
  //             }}
  //           >
  //             {lgUp && (
  //               <img src={Phones} alt="Phones" height={500} width={500} />
  //             )}
  //             {!lgUp && mdUp && (
  //               <img src={Phones} alt="Phones" height={400} width={400} />
  //             )}
  //             {!mdUp && smUp && (
  //               <img src={Phones} alt="Phones" height={500} width={500} />
  //             )}
  //             {!smUp && (
  //               <img src={Phones} alt="Phones" height={"100%"} width={"100%"} />
  //             )}
  //           </Box>
  //           <Box
  //             sx={{
  //               // height: { md: "420px", lg: "520px" },
  //               display: "flex",
  //               alignItems: "center",
  //               width: { sm: "100%", md: "45%", lg: "42%" },
  //               textAlign: { xs: "start", md: "start" },
  //               mt: { xs: 3, md: 0 },
  //               // border: "black solid 1px",
  //             }}
  //           >
  //             <Box
  //             // sx={{ border: "black solid 1px" }}
  //             >
  //               <Typography
  //                 sx={{
  //                   mt: { sm: "0", md: "0vw", lg: "0px" },
  //                   fontSize: { sm: "32px", md: "36px", lg: "42px" },
  //                   letterSpacing: { sm: "-2px", md: "-2px", lg: "-2px" },
  //                   // lineHeight: "42px",
  //                   fontFamily: "Montserrat",
  //                   fontWeight: { sm: 800, md: 800, lg: 800 },
  //                   color: "black",
  //                 }}
  //               >
  //                 {t("download_the_letz_app")}
  //               </Typography>
  //               <Typography
  //                 sx={{
  //                   mt: { sm: "1vw", md: "1vw", lg: "10px" },
  //                   fontSize: { sm: "24px", md: "26px", lg: "30px" },
  //                   letterSpacing: { sm: "-1px", md: "-1px", lg: "-1px" },
  //                   fontFamily: "Montserrat",
  //                   fontWeight: { sm: 700, md: 700, lg: 700 },
  //                   color: "black",
  //                 }}
  //               >
  //                 {t("order_in_one_click")}
  //               </Typography>
  //               <Typography
  //                 sx={{
  //                   // mx: { sm: "5%", md: "0" },
  //                   // width: { sm: "90%", md: "100%" },
  //                   mt: { sm: "1vw", md: "1vw", lg: "10px" },
  //                   fontSize: { sm: "18px", md: "18px", lg: "20px" },
  //                   letterSpacing: { sm: "-1px", md: "-1px", lg: "-1px" },
  //                   fontFamily: "Montserrat",
  //                   lineHeight: { sm: "27px", md: "27px", lg: "30px" },
  //                   fontWeight: 700,
  //                   color: "#444",
  //                   display: "flex",
  //                   justifySelf: "space-around",
  //                 }}
  //               >
  //                 {t("dozens_of_separate_travel_apps")}
  //               </Typography>
  //               <Box
  //                 // className="respons__btn"
  //                 sx={{
  //                   width: { sm: "100%", md: "100%", lg: "100%" },
  //                   // mx: { sm: "7%", md: "0" },
  //                   mt: { sm: 3, md: 3, lg: 3 },
  //                   display: "flex",
  //                   justifyContent: {
  //                     sm: "start",
  //                     md: "start",
  //                   },
  //                 }}
  //               >
  //                 <Box sx={{ mr: { xs: 3, sm: 3, md: 3 } }}>
  //                   <GooglePlayButton />
  //                 </Box>
  //                 <AppStoreButton />
  //               </Box>
  //             </Box>
  //           </Box>
  //         </Box>
  //         {/* </Box> */}
  //       </Container>
  //     </>
  //   );
  // }

  return (
    <Container
      id="Main"
      aria-hidden="true"
      sx={{
        minHeight: "calc( 100vh - 100px)",
        width: "100%",
        pt: 8,
      }}
    >
      {!smUp && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            pt: "50px",
          }}
        >
          {/*-----------------------------------------------DOWNLOAD APP LETZ-------------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "100vw",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={Phones} alt="Phones" height={"100%"} width={"100%"} />
          </Box>
          <Box sx={{ height: "100%", width: "100%", textAlign: "start" }}>
            <Typography
              sx={{
                mt: "30px",
                fontSize: "12vw",
                letterSpacing: "-2px",
                fontFamily: "Montserrat",
                fontWeight: 800,
                color: "black",
              }}
            >
              {t("download_the_letz_app")}
            </Typography>
            <Typography
              sx={{
                mt: "5px",
                fontSize: "8vw",
                letterSpacing: "-1px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                color: "black",
              }}
            >
              {t("order_in_one_click")}
            </Typography>
            <Typography
              sx={{
                mt: "10px",
                fontSize: "5.5vw",
                letterSpacing: "-1px",
                fontFamily: "Montserrat",
                fontWeight: 700,
                color: "#444",
                display: "flex",
                justifySelf: "space-around",
              }}
            >
              {t("dozens_of_separate_travel_apps")}
            </Typography>
            <Box
              className="respons__btn"
              sx={{
                mt: "30px",
                display: "flex",
              }}
            >
              <Box sx={{ mr: 3 }}>
                <GooglePlayButton />
              </Box>
              <AppStoreButton />
            </Box>
          </Box>
          {/*-----------------------------------------------VISA PROMO--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "15vw",
              }}
            >
              <Typography
                sx={{
                  letterSpacing: "-2px",
                  px: 0,
                  fontSize: "8vw",
                  lineHeight: "10vw",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "black",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                {" "}
                {t("world_of_privilege")} <br /> {t("with_visa_and_letz")}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: "3vw",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <VisaPromo width={"100%"} height={"100%"} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: "8vw" }}>
              <Box
                sx={{
                  borderLeft: "solid 2px #3e4958",
                  pl: "3vw",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "3vw",
                    color: "#3e4958",
                  }}
                >
                  {t("visa_promo_50%")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "3vw",
                    color: "#3e4958",
                  }}
                >
                  {t("visa_promo_20%")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "3vw",
                    color: "#3e4958",
                  }}
                >
                  {t("visa_promo_50%_maib")}
                </Typography>
              </Box>
              <Typography
                sx={{
                  mt: 3,
                  fontStyle: "italic",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  fontSize: "3.3vw",
                  color: "#3e4958",
                }}
              >
                {"*" + t("the_promotion_is_valid_only_for_visa")}
              </Typography>
            </Box>
          </Box>
          {/*-----------------------------------------------VARA VARA--------------------------------------------------------------------------------------*/}
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "10vw",
              }}
            >
              <Typography
                sx={{
                  letterSpacing: "-2px",
                  px: 0,
                  fontSize: "8vw",
                  lineHeight: "10vw",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "black",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                {" "}
                {t("summer_with")} <br /> {t("vara_vara")}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: "3vw",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <VaraVara width={"100%"} height={"100%"} />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: "8vw" }}>
              <Box
                sx={{
                  borderLeft: "solid 2px #3e4958",
                  pl: "3vw",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "3vw",
                    color: "#3e4958",
                  }}
                >
                  {t("discount_on_entry_by_using_LetzTaxi")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "3vw",
                    color: "#3e4958",
                  }}
                >
                  {t("the_promo_code_LetzTaxi_must_be_entered")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "3vw",
                    color: "#3e4958",
                  }}
                >
                  {t("the_discount_on_this_voucher_2_tickets")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "3vw",
                    color: "#3e4958",
                  }}
                >
                  {t("this_discount_cannot_be_combined")}
                </Typography>
              </Box>
              <Typography
                sx={{
                  mt: 3,
                  fontStyle: "italic",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  fontSize: "3.3vw",
                  color: "#3e4958",
                }}
              >
                {"*" + t("the_discount_is_only_2024_season")}
              </Typography>
            </Box>
          </Box> */}
          {/*-----------------------------------------------BECOME A DRIVER--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: "8vw",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  pt: "3vw",
                  fontSize: "8vw",
                  letterSpacing: "-2px",
                  lineHeight: "10vw",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "black",
                  width: "100%",
                  textAlign: "start",
                }}
              >
                {t("register_and")} <br /> {t("become_a_driver")}
              </Typography>
              <Box
                sx={{
                  height: "60vw",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mt: "5vw",
                }}
              >
                <UndrawCityDriver height={"100%"} width={"100%"} />
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "3vw" }}
              >
                <Box
                  sx={{
                    borderLeft: "solid 2px #3e4958",
                    pl: "3vw",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "3vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("working_according_to_legal_provisions")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "3vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("bonus_system_and_additional_benefits_every_month")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "3vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("the_possibility_to_buy_a_leased_car_at_the_best_price")}
                  </Typography>
                </Box>
              </Box>
              <Box
                className="respons__btn420px"
                sx={{
                  width: "100%",
                  mt: "30px",
                  display: "flex",
                }}
              >
                <Box sx={{ mr: 3 }}>
                  <Join />
                </Box>
                <DownloadAppDriver />
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------INTRODUCING THE MOBILE APP------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                letterSpacing: "-2px",
                mt: "12vw",
                width: "100%",
                lineHeight: "10vw",
                fontSize: "8vw",
                fontFamily: "Montserrat",
                fontWeight: 600,
                color: "black",
                textAlign: "start",
              }}
            >
              {t("meet_the_super_mobility_app")}
            </Typography>
            <Box
              sx={{
                // borderTop: "solid black 1px",
                // pt: "10vw",
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "5vw",
              }}
            >
              <LocationPin height={"100%"} width={"100%"} />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    mt: 3,
                    paddingLeft: "15px",
                    borderLeft: "solid #3e4958 2px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "3vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("discover_the_ultimate_taxi_app")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "3vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("book_rides_track_your_driver")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    pt: 4,
                  }}
                >
                  <LetsStart />
                </Box>
              </Box>
            </Box>
          </Box>
          {/*----------------------------------------------------------CONTACT-----------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "35vw",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: "15vw",
            }}
          >
            <Typography
              sx={{
                fontSize: "2.5vw",
                fontFamily: "Montserrat",
                fontWeight: 600,
                letterSpacing: "10px",
                textTransform: "uppercase",
                color: "black",
              }}
            >
              {t("contact")}
            </Typography>
            <Typography
              sx={{
                fontSize: "10vw",
                fontFamily: "Montserrat",
                fontWeight: 800,
                letterSpacing: "0px",
                color: "black",
              }}
            >
              +373 67 635 999
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "4vw",
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "black",
              }}
            >
              <EmailIcon sx={{ mr: "5px" }} /> support@letz.md
            </Typography>
          </Box>
        </Box>
      )}
      {!mdUp && smUp && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            pt: "50px",
          }}
        >
          {/*-----------------------------------------------DOWNLOAD APP LETZ---------------------------------------------------------------------------------------*/}
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                height: "420px",
                width: "40%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={Phones} alt="Phones" height={400} width={400} />
            </Box>
            <Box sx={{ height: "420px", width: "60%", textAlign: "center" }}>
              <Typography
                sx={{
                  mt: "4vw",
                  fontSize: "32px",
                  letterSpacing: "-2px",
                  fontFamily: "Montserrat",
                  fontWeight: 800,
                  color: "black",
                }}
              >
                {t("download_the_letz_app")}
              </Typography>
              <Typography
                sx={{
                  mt: "1vw",
                  fontSize: "24px",
                  letterSpacing: "-1px",
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  color: "black",
                }}
              >
                {t("order_in_one_click")}
              </Typography>
              <Typography
                sx={{
                  mx: "5%",
                  width: "90%",
                  mt: "1vw",
                  fontSize: "19px",
                  letterSpacing: "-1px",
                  fontFamily: "Montserrat",
                  lineHeight: "3.5vw",
                  fontWeight: 700,
                  color: "#444",
                  display: "flex",
                  justifySelf: "space-around",
                }}
              >
                {t("dozens_of_separate_travel_apps")}
              </Typography>
              <Box
                className="respons__btn"
                sx={{
                  width: "86%",
                  mx: "7%",
                  mt: "2vw",
                  display: "flex",
                  justifyContent: "space-between" && "space-around",
                }}
              >
                <GooglePlayButton />
                <AppStoreButton />
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------VISA PROMO--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 5,
              width: "100%",
            }}
          >
            <Box
              sx={{
                maxWidth: "calc((723px / 100) * 80)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  letterSpacing: "-2px",
                  fontSize: "40px",
                  lineHeight: "50px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "center",
                }}
              >
                {t("world_of_privilege")} <br /> {t("with_visa_and_letz")}
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <VisaPromo width={(723 / 100) * 80} height={(443 / 100) * 80} />
              </Box>
              <Box sx={{ height: "100%", width: "100%" }}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      paddingLeft: "15px",
                      borderLeft: "solid #3e4958 2px",
                      textAlign: "left",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "21px",
                      lineHeight: "40px",
                      color: "#000",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "25px",
                        marginLeft: "0px",
                        color: "#3e4958",
                      }}
                    >
                      {t("visa_promo_50%")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "25px",
                        marginLeft: "0px",
                        color: "#3e4958",
                      }}
                    >
                      {t("visa_promo_20%")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "25px",
                        marginLeft: "0px",
                        color: "#3e4958",
                      }}
                    >
                      {t("visa_promo_50%_maib")}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      mt: 3,
                      fontStyle: "italic",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "#3e4958",
                    }}
                  >
                    {"*" + t("the_promotion_is_valid_only_for_visa")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------VARA VARA--------------------------------------------------------------------------------------*/}
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 10,
              width: "100%",
            }}
          >
            <Box
              sx={{
                maxWidth: "calc((723px / 100) * 80)",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  letterSpacing: "-2px",
                  fontSize: "40px",
                  lineHeight: "50px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "center",
                }}
              >
                {t("summer_with")} <br /> {t("vara_vara")}
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <VaraVara width={(723 / 100) * 80} height={(443 / 100) * 80} />
              </Box>
              <Box sx={{ height: "100%", width: "100%" }}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <Box
                    sx={{
                      paddingLeft: "15px",
                      borderLeft: "solid #3e4958 2px",
                      textAlign: "left",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "21px",
                      lineHeight: "40px",
                      color: "#000",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "25px",
                        marginLeft: "0px",
                        color: "#3e4958",
                      }}
                    >
                      {t("discount_on_entry_by_using_LetzTaxi")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "25px",
                        marginLeft: "0px",
                        color: "#3e4958",
                      }}
                    >
                      {t("the_promo_code_LetzTaxi_must_be_entered")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "25px",
                        marginLeft: "0px",
                        color: "#3e4958",
                      }}
                    >
                      {t("the_discount_on_this_voucher_2_tickets")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "15px",
                        lineHeight: "25px",
                        marginLeft: "0px",
                        color: "#3e4958",
                      }}
                    >
                      {t("this_discount_cannot_be_combined")}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      mt: 3,
                      fontStyle: "italic",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "15px",
                      color: "#3e4958",
                      width: "100%",
                    }}
                  >
                    {"*" + t("the_discount_is_only_2024_season")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box> */}
          {/*-----------------------------------------------BECOME A DRIVER--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: 10,
            }}
          >
            <Box
              sx={{
                width: "calc((722px / 100) * 80)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "40px",
                  fontFamily: "Montserrat",
                  letterSpacing: "-2px",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "center",
                }}
              >
                {t("register_and")} <br /> {t("become_a_driver")}
              </Typography>
              <UndrawCityDriver
                height={(442 / 100) * 80}
                width={(722 / 100) * 80}
              />
              <Box sx={{ borderLeft: "solid 2px #3e4958", pl: "15px", mt: 3 }}>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "25px",
                    color: "#3e4958",
                  }}
                >
                  {t("working_according_to_legal_provisions")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "25px",
                    color: "#3e4958",
                  }}
                >
                  {t("bonus_system_and_additional_benefits_every_month")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "25px",
                    color: "#3e4958",
                  }}
                >
                  {t("the_possibility_to_buy_a_leased_car_at_the_best_price")}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "start",
                  mt: 3,
                }}
              >
                <Box sx={{ mr: 3 }}>
                  <Join />
                </Box>
                <DownloadAppDriver />
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------INTRODUCING THE MOBILE APP------------------------------------------------------------------------------*/}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              mt: 10,
            }}
          >
            <Box
              sx={{
                width: "calc((722px / 100) * 80)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LocationPin height={(442 / 100) * 80} width={(722 / 100) * 80} />
              <Typography
                sx={{
                  mt: 3,
                  letterSpacing: "-2px",
                  lineHeight: "40px",
                  fontSize: "40px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  color: "black",
                  textAlign: "start",
                }}
              >
                {t("meet_the_super_mobility_app")}
              </Typography>
              <Box
                sx={{
                  mt: 3,
                  paddingLeft: "15px",
                  borderLeft: "solid #3e4958 2px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "13px",
                    color: "#3e4958",
                  }}
                >
                  {t("discover_the_ultimate_taxi_app")}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "13px",
                    color: "#3e4958",
                  }}
                >
                  {t("book_rides_track_your_driver")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "start", mt: 3 }}>
                <LetsStart />
              </Box>
            </Box>
          </Box>
          {/*----------------------------------------------------------CONTACT-----------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "180px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 10,
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                letterSpacing: "10px",
                textTransform: "uppercase",
                color: "black",
              }}
            >
              {t("contact")}
            </Typography>
            <Typography
              sx={{
                fontSize: "50px",
                fontFamily: "Montserrat",
                fontWeight: 800,
                letterSpacing: "0px",
                color: "black",
              }}
            >
              +373 67 635 999
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "black",
              }}
            >
              <EmailIcon sx={{ mr: "5px" }} /> support@letz.md
            </Typography>
          </Box>
        </Box>
      )}
      {!lgUp && mdUp && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pt: "75px",
          }}
        >
          {/*-----------------------------------------------DOWNLOAD APP LETZ--------------------------------------------------------------------------------*/}
          <Box
            sx={{
              width: "100%",
              height: "400px",
              display: "flex",
              justifyContent: "space-between",
              // border: "black solid 1px",
            }}
          >
            <Box
              sx={{
                // height: "460px",
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={Phones} alt="Phones" height={400} width={400} />
            </Box>
            <Box
              sx={{
                // height: "460px",
                display: "flex",
                alignItems: "center",
                width: "45%",
                textAlign: "start",
                // border: "black solid 1px",
              }}
            >
              <Box
              // sx={{ border: "black solid 1px" }}
              >
                <Typography
                  sx={{
                    // mt: "5vw",
                    fontSize: "36px",
                    letterSpacing: "-2px",
                    fontFamily: "Montserrat",
                    fontWeight: 800,
                    color: "black",
                  }}
                >
                  {t("download_the_letz_app")}
                </Typography>
                <Typography
                  sx={{
                    mt: "1vw",
                    fontSize: "26px",
                    letterSpacing: "-1px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    color: "black",
                  }}
                >
                  {t("order_in_one_click")}
                </Typography>
                <Typography
                  sx={{
                    // mx: "5%",
                    // width: "90%",
                    mt: "1vw",
                    fontSize: "18px",
                    letterSpacing: "-1px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    color: "#444",
                    display: "flex",
                    justifySelf: "space-around",
                  }}
                >
                  {t("dozens_of_separate_travel_apps")}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    // mx: "7%",
                    mt: "2vw",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <Box sx={{ mr: 3 }}>
                    <GooglePlayButton />
                  </Box>
                  <AppStoreButton />
                </Box>
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------VISA PROMO--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "calc((442px / 100) * 60)",
              width: "100%",
              mt: 10,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "32px",
                      lineHeight: "40px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "start",
                      letterSpacing: "-2px",
                    }}
                  >
                    {t("world_of_privilege")} <br /> {t("with_visa_and_letz")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderLeft: "solid #3e4958 2px",
                    mt: "1vw",
                    pl: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("visa_promo_50%")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("visa_promo_20%")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("visa_promo_50%_maib")}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    mt: 2,
                    fontStyle: "italic",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: "13px",
                    color: "#3e4958",
                  }}
                >
                  {"*" + t("the_promotion_is_valid_only_for_visa")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <VisaPromo width={(722 / 100) * 60} height={(442 / 100) * 60} />
            </Box>
          </Box>
          {/*-----------------------------------------------VARA VARA--------------------------------------------------------------------------------------*/}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "calc((442px / 100) * 60)",
              width: "100%",
              mt: 10,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <VaraVara width={(722 / 100) * 60} height={(442 / 100) * 60} />
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "45%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "32px",
                      lineHeight: "40px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "start",
                      letterSpacing: "-2px",
                    }}
                  >
                    {t("summer_with")} <br /> {t("vara_vara")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderLeft: "solid #3e4958 2px",
                    mt: "1vw",
                    pl: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("discount_on_entry_by_using_LetzTaxi")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("the_promo_code_LetzTaxi_must_be_entered")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("the_discount_on_this_voucher_2_tickets")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("this_discount_cannot_be_combined")}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    mt: 2,
                    fontStyle: "italic",
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: "13px",
                    color: "#3e4958",
                  }}
                >
                  {"*" + t("the_discount_is_only_2024_season")}
                </Typography>
              </Box>
            </Box>
          </Box> */}
          {/*-----------------------------------------------BECOME A DRIVER--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              minHeight: "calc((442px / 100) * 60)",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mt: 10,
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <UndrawCityDriver
                height={(442 / 100) * 60}
                width={(722 / 100) * 60}
              />
            </Box>
            <Box
              sx={{
                width: "45%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: "32px",
                      lineHeight: "40px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      color: "black",
                      textAlign: "start",
                      letterSpacing: "-2px",
                    }}
                  >
                    {t("register_and")} <br /> {t("become_a_driver")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderLeft: "solid #3e4958 2px",
                    mt: "1vw",
                    pl: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("working_according_to_legal_provisions")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("bonus_system_and_additional_benefits_every_month")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "1.6vw",
                      color: "#3e4958",
                    }}
                  >
                    {t("the_possibility_to_buy_a_leased_car_at_the_best_price")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                  }}
                >
                  <Box sx={{ display: "flex", mt: 2.5 }}>
                    <Box sx={{ mr: 3 }}>
                      <Join />
                    </Box>
                    <DownloadAppDriver />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------INTRODUCING THE MOBILE APP------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "calc((442px / 100) * 60)",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mt: 10,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "45%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  pr: 2,
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    letterSpacing: "-2px",
                    lineHeight: "40px",
                    fontSize: "34px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "start",
                  }}
                >
                  {t("meet_the_super_mobility_app")}
                </Typography>
                <Box
                  sx={{
                    mt: "1vw",
                    paddingLeft: "15px",
                    borderLeft: "solid #3e4958 2px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      lineHeight: "1.6vw",
                      fontSize: "12px",
                      color: "#3e4958",
                    }}
                  >
                    {t("discover_the_ultimate_taxi_app")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      lineHeight: "1.6vw",
                      fontSize: "12px",
                      color: "#3e4958",
                    }}
                  >
                    {t("book_rides_track_your_driver")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "start", pt: 4 }}>
                  <LetsStart />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LocationPin height={(442 / 100) * 60} width={(722 / 100) * 60} />
            </Box>
          </Box>
          {/*----------------------------------------------------------CONTACT-----------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "180px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 8,
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                letterSpacing: "10px",
                textTransform: "uppercase",
                color: "black",
              }}
            >
              {t("contact")}
            </Typography>
            <Typography
              sx={{
                fontSize: "50px",
                fontFamily: "Montserrat",
                fontWeight: 800,
                letterSpacing: "0px",
                color: "black",
              }}
            >
              +373 67 635 999
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "black",
              }}
            >
              <EmailIcon sx={{ mr: "5px" }} /> support@letz.md
            </Typography>
          </Box>
        </Box>
      )}
      {lgUp && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            pt: "100px",
          }}
        >
          {/*-----------------------------------------------DOWNLOAD APP LETZ--------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "500px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              // border: "black solid 1px",
            }}
          >
            <Box
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src={Phones} alt="Phones" height={500} width={500} />
            </Box>
            <Box
              sx={{
                width: "42%",
                display: "flex",
                alignItems: "center",
                textAlign: "start",
                // border: "black solid 1px",
              }}
            >
              <Box
              // sx={{ border: "black solid 1px" }}
              >
                <Typography
                  sx={{
                    // mt: "80px",
                    fontSize: "42px",
                    letterSpacing: "-2px",
                    fontFamily: "Montserrat",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                    fontWeight: 800,
                    color: "black",
                  }}
                >
                  {t("download_the_letz_app")}
                </Typography>
                <Typography
                  sx={{
                    mt: "10px",
                    fontSize: "30px",
                    letterSpacing: "-1px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    color: "black",
                  }}
                >
                  {t("order_in_one_click")}
                </Typography>
                <Typography
                  sx={{
                    // mx: "10%",
                    // width: "80%",
                    mt: "10px",
                    fontSize: "20px",
                    letterSpacing: "-1px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    color: "#444",
                    display: "flex",
                    justifySelf: "space-around",
                  }}
                >
                  {t("dozens_of_separate_travel_apps")}
                </Typography>
                <Box sx={{ mt: 3, display: "flex", justifyContent: "start" }}>
                  <Box sx={{ mr: 3 }}>
                    <GooglePlayButton />
                  </Box>
                  <AppStoreButton />
                </Box>
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------VISA PROMO--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "calc((443px / 100) * 80)",
              width: "100%",
              mt: 10,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "42%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      mb: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        letterSpacing: "-2px",
                        fontSize: "40px",
                        lineHeight: "50px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        color: "black",
                        width: "100%",
                        textAlign: "start",
                      }}
                    >
                      {" "}
                      {t("world_of_privilege")} <br /> {t("with_visa_and_letz")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: "15px",
                      borderLeft: "solid #3e4958 2px",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("visa_promo_50%")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("visa_promo_20%")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("visa_promo_50%_maib")}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      mt: 2,
                      fontStyle: "italic",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#3e4958",
                    }}
                  >
                    {"*" + t("the_promotion_is_valid_only_for_visa")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "50%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <VisaPromo width={(722 / 100) * 80} height={(442 / 100) * 80} />
            </Box>
          </Box>
          {/*-----------------------------------------------VARA VARA--------------------------------------------------------------------------------------*/}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "calc((443px / 100) * 80)",
              width: "100%",
              mt: 15,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "50%",
                display: "flex",
                justifyContent: "end",
                backgroundImage: "linear-gradient(#f2f2f2, #fff)",
              }}
            >
              <VaraVara height={(442 / 100) * 80} width={(722 / 100) * 80} />
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "42%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      mb: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        letterSpacing: "-2px",
                        fontSize: "40px",
                        lineHeight: "50px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        color: "black",
                        width: "100%",
                        textAlign: "start",
                      }}
                    >
                      {" "}
                      {t("summer_with")} <br /> {t("vara_vara")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: "15px",
                      borderLeft: "solid #3e4958 2px",
                      textAlign: "left",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("discount_on_entry_by_using_LetzTaxi")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("the_promo_code_LetzTaxi_must_be_entered")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("the_discount_on_this_voucher_2_tickets")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("this_discount_cannot_be_combined")}
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      mt: 2,
                      fontStyle: "italic",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#3e4958",
                    }}
                  >
                    {"*" + t("the_discount_is_only_2024_season")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box> */}
          {/*-----------------------------------------------BECOME A DRIVER--------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "calc((442px / 100) * 80)",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mt: 15,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box
                sx={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <UndrawCityDriver
                  height={(442 / 100) * 80}
                  width={(722 / 100) * 80}
                />
              </Box>
              <Box
                sx={{
                  height: "100%",
                  width: "42%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      mb: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        letterSpacing: "-2px",
                        fontSize: "40px",
                        lineHeight: "50px",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        color: "black",
                        textAlign: "start",
                      }}
                    >
                      {" "}
                      {t("register_and")} <br /> {t("become_a_driver")}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      paddingLeft: "15px",
                      borderLeft: "solid #3e4958 2px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("working_according_to_legal_provisions")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t("bonus_system_and_additional_benefits_every_month")}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "13px",
                        color: "#3e4958",
                      }}
                    >
                      {t(
                        "the_possibility_to_buy_a_leased_car_at_the_best_price"
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    <Box sx={{ display: "flex", mt: "30px" }}>
                      <Box sx={{ mr: 3 }}>
                        <Join />
                      </Box>
                      <DownloadAppDriver />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/*-----------------------------------------------INTRODUCING THE MOBILE APP------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "calc((442px / 100) * 80)",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mt: 15,
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "42%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    width: "100%",
                    mb: 3,
                    letterSpacing: "-2px",
                    fontSize: "44px",
                    lineHeight: "50px",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    color: "black",
                    textAlign: "start",
                  }}
                >
                  {t("meet_the_super_mobility_app")}
                </Typography>
                <Box
                  sx={{
                    paddingLeft: "15px",
                    borderLeft: "solid #3e4958 2px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "13px",
                      color: "#3e4958",
                    }}
                  >
                    {t("discover_the_ultimate_taxi_app")}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      fontSize: "13px",
                      color: "#3e4958",
                    }}
                  >
                    {t("book_rides_track_your_driver")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "start", pt: 4 }}>
                  <LetsStart />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <LocationPin height={(442 / 100) * 80} width={(722 / 100) * 80} />
            </Box>
          </Box>
          {/*----------------------------------------------------------CONTACT-----------------------------------------------------------------------------------------*/}
          <Box
            sx={{
              height: "180px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mt: 10,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Montserrat",
                fontWeight: 600,
                letterSpacing: "10px",
                textTransform: "uppercase",
                color: "black",
              }}
            >
              {" "}
              {t("contact")}
            </Typography>
            <Typography
              sx={{
                fontSize: "56px",
                fontFamily: "Montserrat",
                fontWeight: 800,
                letterSpacing: "0px",
                color: "black",
              }}
            >
              {" "}
              +373 67 635 999
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: "black",
              }}
            >
              <EmailIcon sx={{ mr: "5px" }} /> support@letz.md
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default Main;
