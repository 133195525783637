import React, { useState } from "react";
// import logo from "../images/Logo/letz_logo.png";
import LetzLogo from "../Icons/letz_logo";
// import letzLogoNewYear from '../images/Logo/letz_logo_new_year.png';
import {
  Box,
  Button,
  AppBar,
  Drawer,
  IconButton,
  // ListItem,
  createTheme,
  ThemeProvider,
  Typography,
  List,
  Toolbar,
  MenuItem,
  Menu,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import MenuIcon from "@mui/icons-material/Menu";
import "../styles/App.css";
import { useTranslation } from "react-i18next";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//import LanguageIcon from '../Icons/language_icon';
import LanguageIcon from "@mui/icons-material/Language";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import KeyIcon from "@mui/icons-material/Key";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { saveLanguage } from "../lib/language";
import { getLanguage } from "../lib/language";
// import SnowAnimation from '../components/snow/snow';

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
});

const drawerWidth = "100%";
// const navItems = ['driver', 'contact'];
const driverItems = ["instruction", "download", "join"];
const driverPages = ["driver/instruction", "driver", "become_a_driver"];
const lngs = ["en", "ro", "ru", "uk"];
const lngsS = ["en-US", "ro-RO", "ru-RU", "uk-UA"];
const browserlang = window.navigator.language;

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [driverOpen, setDriverOpen] = useState(false);
  const [languageOpen, setLanguageOpen] = useState(false);
  const { t } = useTranslation(["header", "common"]);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(browserlang);

  switch (language) {
    case "en":
      break;
    case "en-US":
      break;
    case "ro":
      break;
    case "ro-RO":
      break;
    case "ru":
      break;
    case "ru-RU":
      break;
    case "uk":
      break;
    case "uk-UA":
      break;
    default:
      setLanguage("en");
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    if (languageOpen !== false) {
      setLanguageOpen(false);
    }
    if (driverOpen !== false) {
      setDriverOpen(false);
    }
  };

  // const languageDrawerToggle = () => {
  //   setLanguageOpen(!languageOpen);
  // };

  const selectLanguageMobile = () => {
    languageOpen ? setLanguageOpen(false) : setLanguageOpen(true);
    if (driverOpen === true) {
      setDriverOpen(false);
    }
  };

  const driverDrawerToggle = () => {
    driverOpen ? setDriverOpen(false) : setDriverOpen(true);
    if (languageOpen === true) {
      setLanguageOpen(false);
    }
  };

  const drawerToolBar = (
    <>
      <Box
        sx={{
          pt: 1,
          px: 3,
          transition: "0.5s",
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          height: languageOpen
            ? languageOpen
              ? "308px"
              : "153px"
            : driverOpen
            ? "258px"
            : "148px",
        }}
      >
        <List>
          <Typography
            onClick={driverDrawerToggle}
            className="headButtonsMobile"
            sx={{
              position: "relative",
              width: "100%",
              textAlign: "start",
              fontFamily: "Montserrat",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            <LocalTaxiIcon sx={{ my: "-6px", mr: 1 }} />
            {t("driver")}
            <KeyboardArrowDownRoundedIcon
              sx={{
                position: "absolute",
                right: 0,
                transform: driverOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "ease-in-out 0.25s",
              }}
            />
          </Typography>
        </List>
        <Box
          sx={{
            transition: "0.5s",
            height: driverOpen ? "110px" : "0px",
            overflowY: "hidden",
          }}
        >
          {driverPages.map((item, i) => (
            <NavLink
              key={item}
              style={{ color: "#fff", textDecoration: "none" }}
              to={`/${item}`}
            >
              <Typography
                onClick={() => {
                  driverDrawerToggle();
                  handleDrawerToggle();
                }}
                key={item}
                className="headButtonsMobile"
                sx={{
                  textAlign: "start",
                  color: "#fff",
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: 600,
                  justifyContent: "center",
                  userSelect: "none",
                  mt: 1.3,
                  mb: 1,
                  pl: 4,
                }}
              >
                {" "}
                {t(driverItems[i])}
              </Typography>
            </NavLink>
          ))}
        </Box>

        <List>
          <Box
            onClick={handleDrawerToggle}
            sx={{
              mt: "-2px",
              fontFamily: "Montserrat",
              fontSize: 16,
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <KeyIcon sx={{ my: "-6px", mr: 1 }} />
            <NavLink
              to={`/car_rent`}
              className="menuNavlinkButtonsMobile"
              style={{ width: "100%", textAlign: "start" }}
            >
              {t("rental")}
            </NavLink>
          </Box>
        </List>

        <Box
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            textAlign: "center",
            pt: 1,
          }}
        >
          <Typography
            onClick={selectLanguageMobile}
            className="headButtonsMobile"
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              fontFamily: "Montserrat",
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            <LanguageIcon className="rotate45" sx={{ my: "-6px", mr: 1 }} />
            {t("language")}
            <KeyboardArrowDownRoundedIcon
              sx={{
                position: "absolute",
                right: 0,
                transform: languageOpen ? "rotate(180deg)" : "rotate(0deg)",
                transition: "ease-in-out 0.25s",
              }}
            />
          </Typography>
          <Box
            sx={{
              transition: "0.5s",
              height: languageOpen ? "160px" : "15px",
              overflowY: "hidden",
            }}
          >
            {lngs.map((lng, i) => (
              <Typography
                key={lng}
                onClick={() => {
                  i18n.changeLanguage(lng);
                  setLanguage(lng);
                  handleDrawerToggle();
                }}
                className="headButtonsMobile"
                sx={{
                  color: "#fff",
                  userSelect: "none",
                  textAlign: "start",
                  fontFamily: "Montserrat",
                  fontSize: 16,
                  fontWeight: 600,
                  mt: 2,
                  pl: 4,
                  mb: lngs.length === i + 1 ? 2 : 0,
                }}
              >
                {" "}
                {t(lng)}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex", zIndex: 3 }}>
        <ThemeProvider theme={theme}>
          <AppBar component="nav" sx={{ height: "100px" }}>
            <Box
              // id="snow-container"
              sx={{
                height: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "space-between" },
              }}
            >
              {/* <SnowAnimation /> */}

              {/* <NavLink to='/' style={{ marginLeft: '5vw', marginTop: -12 }}>
              <img src={letzLogoNewYear} alt='Logo' height={37} width={100}/>
            </NavLink> */}

              <NavLink
                to="/"
                onClick={() => {
                  if (mobileOpen) {
                    handleDrawerToggle();
                  }
                }}
                style={{ marginLeft: "5vw" }}
              >
                <Box sx={{ width: "100px", height: "25px" }}>
                  <LetzLogo />
                </Box>

                {/* <img src={logo} alt="Logo" height={25} width={100} /> */}
              </NavLink>
              <Toolbar sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  sx={{ mr: "5vw", display: { sm: "none" } }}
                >
                  <MenuIcon sx={{ mr: "-15px" }} />
                </IconButton>
                <Box
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    mr: "5vw",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box
                    sx={{
                      mt: "-2px",
                      fontFamily: "Montserrat",
                      fontSize: 16,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      px: 2,
                    }}
                  >
                    <NavLink to={`/car_rent`} className="menuNavlinkButtons">
                      {t("rental")}
                    </NavLink>
                  </Box>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          className="headButtons"
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: 16,
                            fontWeight: 600,
                            minWidth: 0,
                            px: 2,
                            color: "#fff",
                            textTransform: "none",
                          }}
                          {...bindTrigger(popupState)}
                        >
                          {t("driver")}
                        </Button>
                        <Menu
                          sx={{ ml: "-15px", mt: "0px" }}
                          {...bindMenu(popupState)}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#000",
                              mb: -1,
                              mt: -2,
                              py: 1,
                              pr: 0,
                              fontFamily: "Montserrat",
                              fontSize: 16,
                              fontWeight: 600,
                              px: 2,
                              width: "auto",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {driverItems.map((item, i) => (
                              <NavLink
                                to={`/${driverPages[i]}`}
                                className="menuNavlinkButtons"
                                key={item}
                                onClick={popupState.close}
                              >
                                {t(item)}
                              </NavLink>
                            ))}
                          </Box>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <Button
                          className="headButtons"
                          id="EN"
                          sx={{
                            fontFamily: "Montserrat",
                            fontSize: 16,
                            fontWeight: 600,
                            mx: "0.8vw",
                            color: "#fff",
                            textTransform: "none",
                          }}
                          {...bindTrigger(popupState)}
                        >
                          <LanguageIcon
                            className="rotate45"
                            sx={{ mr: "5px" }}
                          />{" "}
                          {t("language")}
                        </Button>
                        <Menu
                          sx={{ ml: "-1.5vw", mt: "0px" }}
                          {...bindMenu(popupState)}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#000",
                              mb: -1,
                              mt: -2,
                              py: 1,
                              pr: 0,
                              fontFamily: "Montserrat",
                              fontSize: 16,
                              fontWeight: 600,
                              width: 150,
                            }}
                          >
                            {lngs.map((lng, i) => (
                              <MenuItem
                                className="headButtons"
                                sx={{
                                  color: (
                                    getLanguage()
                                      ? getLanguage() === lng
                                      : language === lng ||
                                        language === lngsS[i]
                                  )
                                    ? "#797af0"
                                    : "#fff",
                                  fontFamily: "Montserrat",
                                  fontSize: 16,
                                  fontWeight: 600,
                                  justifyContent: "center",
                                }}
                                key={lng}
                                onClick={() => {
                                  i18n.changeLanguage(lng, popupState.close);
                                  saveLanguage(lng);
                                  setLanguage(lng);
                                }}
                              >
                                {t(lng)}
                              </MenuItem>
                            ))}
                          </Box>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </Box>
              </Toolbar>
            </Box>
          </AppBar>
          <Box>
            <Drawer
              id="handleDrawerToggle"
              container={container}
              variant="temporary"
              anchor="top"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawerToolBar}
            </Drawer>
          </Box>
        </ThemeProvider>
      </Box>
    </>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
