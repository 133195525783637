import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  useTheme,
  Button,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const phone = /^\+?[1-9][0-9]{0,30}$/;

const RentForm = () => {
  const [searchParams] = useSearchParams();
  const carId = searchParams.get("carId");
  const { t } = useTranslation(["carRent", "common"]);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [name, setName] = useState("");
  const [days, setDays] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isDirty, setDirty] = useState(false);
  const [notANumber, setNotNumber] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    phone.test(Number(phoneNumber)) ? setNotNumber(false) : setNotNumber(true);
  }, [phoneNumber]);
  const SendMessage = async () => {
    setDirty(true);
    if (phoneNumber.length < 3 || phoneNumber.length > 20) {
      return;
    } else if (notANumber) {
      return;
    }
    setLoading(true);
    await fetch("/veritas/v1/Ping/Rent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        ApiKey: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({
        carId: carId ? +carId : undefined,
        name: name ? name : undefined,
        phone: phoneNumber,
        days: days ? +days : undefined,
        message: message ? message : undefined,
      }),
    })
      .then((res) => checkResponse(res))
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(t("message_dont_sent"), { variant: "error" });
      });
  };

  const checkResponse = (res) => {
    if (!res || res.status >= 300) {
      setLoading(false);
      enqueueSnackbar(t("message_dont_sent"), { variant: "error" });
    } else {
      setLoading(false);
      enqueueSnackbar(t("message_sent"), { variant: "success" });
      setName("");
      setPhoneNumber("");
      setDays("");
      setMessage("");
      setDirty(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        width: {
          xs: "calc(75vw + 1.66vw)",
          sm: "calc(460px * 1)",
          md: "calc(460px * 1.5)",
          lg: "calc(460px * 2)",
        },
        pb: 5,
      }}
    >
      <Typography
        sx={{
          pt: 2,
          pb: 5,
          textAlign: "start",
          color: "1a1b1e",
          fontFamily: "Montserrat",
          fontSize: { xs: "4.5vw", sm: 20, md: 24 },
          fontWeight: 700,
          letterSpacing: "0px",
        }}
      >
        {t("fill_out_this_form")}
      </Typography>

      {carId ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "3vw", sm: "17px" },
              fontFamily: "Montserrat",
              fontWeight: 400,
            }}
          >
            {t("how_can_i_call_you")}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: { xs: "2.5vw", sm: "17px" },
              letterSpacing: "-1px",
              border: "black solid 1px",
              px: 1,
              borderRadius: "4px",
            }}
          >
            {t("car_id")} - {carId}
          </Typography>
        </Box>
      ) : (
        <Typography
          sx={{
            fontSize: { xs: "3vw", sm: "17px" },
            fontFamily: "Montserrat",
            fontWeight: 400,
          }}
        >
          {t("how_can_i_call_you")}
        </Typography>
      )}

      <TextField
        id="standard-basic"
        variant="standard"
        value={name ?? ""}
        onChange={(e) => setName(e.target.value)}
        sx={{
          width: "100%",
        }}
      ></TextField>

      <Typography
        sx={{
          fontSize: { xs: "3vw", sm: "17px" },
          fontFamily: "Montserrat",
          fontWeight: 400,
          mt: 2,
          color: "black",
        }}
      >
        {"*" + t("your_phone_number") + ":"}
      </Typography>
      <TextField
        variant="standard"
        value={phoneNumber ?? ""}
        onChange={(e) => setPhoneNumber(e.target.value)}
        error={
          phoneNumber.length === 0 ||
          notANumber ||
          phoneNumber.length < 6 ||
          phoneNumber.length > 20
        }
        sx={{
          width: "100%",
          ".MuiInputBase-root.MuiInput-root": {
            fontFamily: "Montserrat !important",
            fontWeight: "600 !important",
            borderBottom:
              (isDirty && phoneNumber.length === 0) ||
              (isDirty && notANumber) ||
              (isDirty && phoneNumber.length < 3) ||
              (isDirty && phoneNumber.length > 20)
                ? "1px solid red !important"
                : "0px solid #000 !important",
          },
          ".MuiInputBase-root.MuiInput-root:after": {
            borderBottom:
              (isDirty && phoneNumber.length === 0) ||
              (isDirty && notANumber) ||
              (isDirty && phoneNumber.length < 3) ||
              (isDirty && phoneNumber.length > 20)
                ? "1px solid red !important"
                : "0px solid #000 !important",
          },
          ".MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover:not(.Mui-disabled):before":
            {
              borderBottom:
                (isDirty && phoneNumber.length === 0) ||
                (isDirty && notANumber) ||
                (isDirty && phoneNumber.length < 3) ||
                (isDirty && phoneNumber.length > 20)
                  ? "2px solid red !important"
                  : "2px solid #000 !important",
            },
          ".MuiInputBase-root.MuiInput-root.Mui-focused:not(:hover):before": {
            borderBottom:
              (isDirty && phoneNumber.length === 0) ||
              (isDirty && notANumber) ||
              (isDirty && phoneNumber.length < 3) ||
              (isDirty && phoneNumber.length > 20)
                ? "2px solid red !important"
                : "2px solid #000 !important",
          },
        }}
      />
      {(isDirty && phoneNumber.length === 0 && (
        <Typography
          sx={{
            mt: "5px",
            fontSize: { xs: "3vw", sm: "16px" },
            fontFamily: "Montserrat",
            fontWeight: 600,
            color: "red",
            lineHeight: { xs: "3vw", sm: "17px" },
          }}
        >
          {t("enter_the_number")}
        </Typography>
      )) ||
        (isDirty && notANumber && (
          <Typography
            sx={{
              mt: "5px",
              fontSize: { xs: "3vw", sm: "16px" },
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "red",
              lineHeight: { xs: "3vw", sm: "17px" },
            }}
          >
            {t("only_numbers")}
          </Typography>
        )) ||
        (isDirty && phoneNumber.length < 3 && (
          <Typography
            sx={{
              mt: "5px",
              fontSize: { xs: "3vw", sm: "16px" },
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "red",
              lineHeight: { xs: "3vw", sm: "17px" },
            }}
          >
            {t("min_symbols_three")}
          </Typography>
        )) ||
        (isDirty && phoneNumber.length > 20 && (
          <Typography
            sx={{
              mt: "5px",
              fontSize: { xs: "3vw", sm: "16px" },
              fontFamily: "Montserrat",
              fontWeight: 600,
              color: "red",
              lineHeight: { xs: "3vw", sm: "17px" },
            }}
          >
            {t("max_symbols_twenty")}
          </Typography>
        ))}

      {carId && (
        <>
          <Typography
            sx={{
              mt: 2,
              fontSize: { xs: "3vw", sm: "17px" },
              fontFamily: "Montserrat",
              fontWeight: 400,
            }}
          >
            {t("desired_number_of_rental_days")}
          </Typography>

          <TextField
            id="standard-basic"
            variant="standard"
            value={days ?? ""}
            onChange={(e) => {
              const value = e.target.value;
              if (!isNaN(value) && value !== "0" && +value < 1000) {
                setDays(value.trim());
              }
            }}
            sx={{
              width: "100%",
            }}
          ></TextField>
        </>
      )}

      <Typography
        sx={{
          mt: 2,
          fontSize: { xs: "3vw", sm: "17px" },
          fontFamily: "Montserrat",
          fontWeight: 400,
        }}
      >
        {t("message") + ":"}
      </Typography>
      <TextField
        multiline
        rows={3}
        id="standard-basic"
        variant="standard"
        value={message ?? ""}
        onChange={(e) => setMessage(e.target.value)}
        sx={{
          width: "100%",
          mt: 1,
        }}
      ></TextField>

      <Box sx={{ display: "flex", justifyContent: "start", mt: 3 }}>
        <Button
          onClick={SendMessage}
          sx={{
            px: { xs: 1.5, sm: 3 },
            height: { xs: "9.5vw", sm: "50px" },
            background: "#000",
            color: loading ? "#000" : "#fff",
            fontSize: { xs: "3vw", sm: "18px" },
            letterSpacing: { xs: 0, sm: "auto" },
            fontFamily: "Montserrat",
            fontWeight: 600,
            borderRadius: "8px",
            textTransform: "none",
            "&:hover": {
              background: "#2f2e41",
              color: loading ? "#2f2e41" : "#fff",
            },
          }}
        >
          {loading && (
            <CircularProgress
              size={!smUp ? "6vw" : "30px"}
              sx={{ color: "#797af0", position: "absolute" }}
            />
          )}
          {t("send")}
        </Button>
        <Typography
          sx={{
            textAlign: "center",
            mt: { xs: "2.3vw", sm: 2 },
            ml: { xs: 1, sm: 2 },
            fontSize: { xs: "4vw", sm: 20 },
            fontFamily: "Montserrat",
            fontWeight: 400,
          }}
        >
          {t("or")}
        </Typography>
        <Button
          component="a"
          href="tel:+37379545566"
          sx={{
            ml: { xs: 1, sm: 2 },
            px: { xs: 1.5, sm: 3 },
            height: { xs: "9.5vw", sm: "50px" },
            background: "#000",
            color: "#fff",
            fontSize: { xs: "3vw", sm: "18px" },
            letterSpacing: { xs: 0, sm: "auto" },
            fontFamily: "Montserrat",
            fontWeight: 600,
            borderRadius: "8px",
            textTransform: "none",
            "&:hover": {
              background: "#2f2e41",
              color: "#fff",
            },
          }}
        >
          <LocalPhoneIcon sx={{ fontSize: { xs: "5.6vw", sm: "32px" } }} />
        </Button>
      </Box>
    </Box>
  );
};

export default RentForm;
