const carRentStructure = [
  {
    id: 1,
    available: true,
    name: "Toyota Corolla",
    year: 2016,
    price: 20,
    gearbox: "automat",
    fuelType: "benzin",
    doors: 4,
    engineVolume: 1.6,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/toyota1.1.jpg",
      "https://cdn.letz.md/rent/toyota1.2.jpg",
      "https://cdn.letz.md/rent/toyota1.3.jpg",
      "https://cdn.letz.md/rent/toyota1.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 25, 23, 22, 20, "negotiable"],
    ],
  },
  {
    id: 2,
    available: true,
    name: "Toyota Corolla",
    year: 2019,
    price: 35,
    gearbox: "automat",
    fuelType: "benzin_hybrid",
    doors: 5,
    engineVolume: 1.8,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/toyota2.1.jpg",
      "https://cdn.letz.md/rent/toyota2.2.jpg",
      "https://cdn.letz.md/rent/toyota2.3.jpg",
      "https://cdn.letz.md/rent/toyota2.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 40, 38, 36, 35, "negotiable"],
    ],
  },
  {
    id: 3,
    available: true,
    name: "Toyota Camry",
    year: 2019,
    price: 35,
    gearbox: "automat",
    fuelType: "benzin_hybrid",
    doors: 4,
    engineVolume: 2.5,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/toyota3.1.jpg",
      "https://cdn.letz.md/rent/toyota3.2.jpg",
      "https://cdn.letz.md/rent/toyota3.3.jpg",
      "https://cdn.letz.md/rent/toyota3.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 40, 38, 36, 35, "negotiable"],
    ],
  },
  {
    id: 4,
    available: true,
    name: "Toyota C-HR",
    year: 2019,
    price: 35,
    gearbox: "automat",
    fuelType: "benzin_hybrid",
    doors: 5,
    engineVolume: 1.8,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/toyota4.1.jpg",
      "https://cdn.letz.md/rent/toyota4.2.jpg",
      "https://cdn.letz.md/rent/toyota4.3.jpg",
      "https://cdn.letz.md/rent/toyota4.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 40, 38, 36, 35, "negotiable"],
    ],
  },
  {
    id: 5,
    available: true,
    name: "Ford Fusion",
    year: 2014,
    price: 25,
    gearbox: "automat",
    fuelType: "benzin_hybrid",
    doors: 4,
    engineVolume: 2.0,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/ford5.1.jpg",
      "https://cdn.letz.md/rent/ford5.2.jpg",
      "https://cdn.letz.md/rent/ford5.3.jpg",
      "https://cdn.letz.md/rent/ford5.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 30, 28, 26, 25, "negotiable"],
    ],
  },
  {
    id: 6,
    available: true,
    name: "Ford Fusion",
    year: 2014,
    price: 25,
    gearbox: "automat",
    fuelType: "benzin_hybrid",
    doors: 4,
    engineVolume: 2.0,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/ford6.1.jpg",
      "https://cdn.letz.md/rent/ford6.2.jpg",
      "https://cdn.letz.md/rent/ford6.3.jpg",
      "https://cdn.letz.md/rent/ford6.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 30, 28, 26, 25, "negotiable"],
    ],
  },
  {
    id: 7,
    available: true,
    name: "BYD",
    year: 2024,
    price: 40,
    gearbox: "automat",
    fuelType: "benzin_hybrid",
    doors: 4,
    engineVolume: 1.5,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/byd7.1.jpg",
      "https://cdn.letz.md/rent/byd7.2.jpg",
      "https://cdn.letz.md/rent/byd7.3.jpg",
      "https://cdn.letz.md/rent/byd7.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 45, 43, 41, 40, "negotiable"],
    ],
  },
  {
    id: 8,
    available: true,
    name: "Skoda Octavia",
    year: 2024,
    price: 35,
    gearbox: "automat",
    fuelType: "benzin",
    doors: 4,
    engineVolume: 1.6,
    conditioner: {
      flag: true,
      text: "conditioner",
    },
    images: [
      "https://cdn.letz.md/rent/skoda8.1.jpg",
      "https://cdn.letz.md/rent/skoda8.2.jpg",
      "https://cdn.letz.md/rent/skoda8.3.jpg",
      "https://cdn.letz.md/rent/skoda8.4.jpg",
    ],
    tablePrice: [
      ["days", "1-2", "3-7", "8-20", "21-30", "31+"],
      ["price", 40, 38, 36, 35, "negotiable"],
    ],
  },
];

export default carRentStructure;
