import { useState, useEffect, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import RentForm from "../Components/RentForm";
import ImageModalView from "../Components/ImageModalView";
import carRentStructure from "../utils";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AcUnitRoundedIcon from "@mui/icons-material/AcUnitRounded";
import LocalGasStationRoundedIcon from "@mui/icons-material/LocalGasStationRounded";
import MiscellaneousServicesRoundedIcon from "@mui/icons-material/MiscellaneousServicesRounded";
import CarDoor from "../../../Icons/car_door";
import CarEngine from "../../../Icons/car_engine";
import PriceTable from "./PriceTable";
import Calculator from "./Calculator";

const deliveryDescription = [
  "deliveryDescription_1",
  "deliveryDescription_2",
  "deliveryDescription_3",
  "deliveryDescription_4",
];

const RentCarDetails = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const carID = searchParams.get("carId");
  const { t } = useTranslation(["carRent", "common"]);
  const [imageNumber, setImageNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const data = carRentStructure.find((it) => it.id === +carID);
  const touchPadCoordsStartRef = useRef(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const LeftArrow = () => {
    if (imageNumber <= 0) {
      setImageNumber(data?.images?.length - 1);
    } else {
      setImageNumber((prev) => prev - 1);
    }
  };

  const RightArrow = () => {
    if (imageNumber >= data?.images?.length - 1) {
      setImageNumber(0);
    } else {
      setImageNumber((prev) => prev + 1);
    }
  };

  const CircleButtons = (index) => {
    setImageNumber(index);
  };

  const handleToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          onClick={LeftArrow}
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "ease-in-out 0.15s",
            "&:hover": { cursor: "pointer", color: "#999" },
          }}
        >
          <ArrowBackIosNewRoundedIcon
            sx={{ fontSize: { xs: "4vw", sm: 24 } }}
          />
        </Box>
        <Box
          id={`carRent-${data.id}`}
          onTouchStart={(e) => {
            touchPadCoordsStartRef.current = e.changedTouches[0].clientX;
          }}
          onTouchEnd={(e) => {
            if (touchPadCoordsStartRef.current < e.changedTouches[0].clientX - 75) {
              LeftArrow();
            } else if (touchPadCoordsStartRef.current - 75 > e.changedTouches[0].clientX) {
              RightArrow();
            }
            touchPadCoordsStartRef.current = 0;
          }}
          sx={{
            position: "relative",
            mx: { xs: "2vw", sm: 3 },
            height: {
              xs: "calc(50vw + 1.14vw)",
              sm: "calc(307px * 1)",
              md: "calc(307px * 1.5)",
              lg: "calc(307px * 2)",
            },
            width: {
              xs: "calc(75vw + 1.66vw)",
              sm: "calc(460px * 1)",
              md: "calc(460px * 1.5)",
              lg: "calc(460px * 2)",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              background: "#000",
              height: {
                xs: "calc(50vw + 1.14vw)",
                sm: "calc(307px * 1)",
                md: "calc(307px * 1.5)",
                lg: "calc(307px * 2)",
              },
              width: {
                xs: "calc(75vw + 1.66vw)",
                sm: "calc(460px * 1)",
                md: "calc(460px * 1.5)",
                lg: "calc(460px * 2)",
              },
            }}
          ></Box>
          {data.images.map((image, i) => {
            return (
              <img
                onClick={handleToggle}
                style={{
                  position: "absolute",
                  opacity: imageNumber === i ? 1 : 0,
                  transition: "ease-in-out 0.5s",
                  cursor: "pointer",
                }}
                key={i}
                src={image}
                alt="Auto"
                height={"100%"}
                width={"100%"}
              />
            );
          })}
        </Box>

        <Box
          onClick={RightArrow}
          sx={{
            display: "flex",
            alignItems: "center",
            transition: "ease-in-out 0.15s",
            "&:hover": { cursor: "pointer", color: "#999" },
          }}
        >
          <ArrowForwardIosRoundedIcon
            sx={{ fontSize: { xs: "4vw", sm: 24 } }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: { xs: "1.5vw", sm: 1 },
        }}
      >
        {data.images.map((image, i) => (
          <Box
            onClick={() => CircleButtons(i)}
            key={image + i}
            sx={{
              width: { xs: "2vw", sm: "10px" },
              height: { xs: "2vw", sm: "10px" },
              borderRadius: "50%",
              background: imageNumber === i ? "#000" : "#666",
              mr: { xs: "1.5vw", sm: 1 },
              "&:hover": { cursor: "pointer" },
            }}
          ></Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 1,
          width: {
            xs: "calc(75vw + 1.66vw)",
            sm: "calc(460px * 1)",
            md: "calc(460px * 1.5)",
            lg: "calc(460px * 2)",
          },
        }}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontSize: { xs: 16, sm: 20, md: 24 },
            fontWeight: 700,
          }}
        >
          {data.name} {data.year}
        </Typography>
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "end",
              whiteSpace: "nowrap",
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              mr: 1,
              ml: 2,
              pb: { xs: 0.1, sm: 0.2, md: 0.4 },
            }}
          >
            {t("from")}
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "end",
              whiteSpace: "nowrap",
              fontFamily: "Montserrat",
              fontSize: { xs: 16, sm: 20, md: 24 },
              fontWeight: 700,
            }}
          >
            {data.price}€
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "end",
              whiteSpace: "nowrap",
              fontFamily: "Montserrat",
              fontSize: { xs: 12, sm: 14, md: 16 },
              fontWeight: 600,
              pb: { xs: 0.1, sm: 0.2, md: 0.4 },
            }}
          >
            /{t("day")}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between", sm: "space-around" },
          mt: { xs: "3vw", sm: 2 },
          width: {
            xs: "calc(75vw + 1.66vw)",
            sm: "calc(460px * 1)",
            md: "calc(460px * 1.5)",
            lg: "calc(460px * 2)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mr: 1,
          }}
        >
          <Box
            sx={{
              width: { xs: 24, sm: 28, md: 32, lg: 36 },
              height: { xs: 24, sm: 28, md: 32, lg: 36 },
            }}
          >
            <CarDoor />
          </Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "2.5vw", sm: 14, md: 16 },
              fontWeight: 500,
              color: "#3e4958",
              textAlign: "center",
              letterSpacing: { xs: "-0.5px", sm: "0px" },
            }}
          >
            {data.doors} {t(`doors_${data.doors}`)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mr: 1,
          }}
        >
          <Box
            sx={{
              width: { xs: 24, sm: 28, md: 32, lg: 36 },
              height: { xs: 24, sm: 28, md: 32, lg: 36 },
            }}
          >
            <CarEngine />
          </Box>
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "2.5vw", sm: 14, md: 16 },
              fontWeight: 500,
              color: "#3e4958",
              textAlign: "center",
              letterSpacing: { xs: "-0.5px", sm: "0px" },
            }}
          >
            {data.engineVolume} {t("L")}
          </Typography>
        </Box>
        {data.conditioner.flag && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mr: 1,
            }}
          >
            <AcUnitRoundedIcon
              sx={{ fontSize: { xs: 24, sm: 28, md: 32, lg: 36 } }}
            />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: { xs: "2.5vw", sm: 14, md: 16 },
                fontWeight: 500,
                color: "#3e4958",
                textAlign: "center",
                letterSpacing: { xs: "-0.5px", sm: "0px" },
              }}
            >
              {t(data.conditioner.text)}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mr: 1,
          }}
        >
          <MiscellaneousServicesRoundedIcon
            sx={{ fontSize: { xs: 24, sm: 28, md: 32, lg: 36 } }}
          />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "2.5vw", sm: 14, md: 16 },
              fontWeight: 500,
              color: "#3e4958",
              textAlign: "center",
              letterSpacing: { xs: "-0.5px", sm: "0px" },
            }}
          >
            {t(data.gearbox)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mr: 1,
          }}
        >
          <LocalGasStationRoundedIcon
            sx={{ fontSize: { xs: 24, sm: 28, md: 32, lg: 36 } }}
          />
          <Typography
            sx={{
              fontFamily: "Montserrat",
              fontSize: { xs: "2.5vw", sm: 14, md: 16 },
              fontWeight: 500,
              color: "#3e4958",
              textAlign: "center",
              letterSpacing: { xs: "-0.5px", sm: "0px" },
            }}
          >
            {t(data.fuelType)}
          </Typography>
        </Box>
      </Box>

      <Typography
        sx={{
          width: {
            xs: "calc(75vw + 1.66vw)",
            sm: "calc(460px * 1)",
            md: "calc(460px * 1.5)",
            lg: "calc(460px * 2)",
          },
          mt: { xs: 2, md: 5 },
          fontFamily: "Montserrat",
          fontSize: { xs: 16, sm: 20, md: 24 },
          fontWeight: 700,
        }}
      >
        {t("delivery")}
      </Typography>

      {deliveryDescription.map((text, i) => (
        <Typography
          key={i}
          sx={{
            width: {
              xs: "calc(75vw + 1.66vw)",
              sm: "calc(460px * 1)",
              md: "calc(460px * 1.5)",
              lg: "calc(460px * 2)",
            },
            mt: i !== deliveryDescription.length - 1 ? 1 : 2,
            fontFamily: "Montserrat",
            fontSize: { xs: 14, sm: 18, md: 22 },
            fontWeight: i !== deliveryDescription.length - 1 ? 500 : 600,
            letterSpacing:
              i !== deliveryDescription.length - 1 ? "-1px" : "-1px",
            color: i !== deliveryDescription.length - 1 ? "#3e4958" : "#000",
          }}
        >
          {i !== deliveryDescription.length - 1 && "•"} {t(text)}
        </Typography>
      ))}

      <Typography
        sx={{
          borderBottom: "black solid 1px",
          borderTop: "black solid 1px",
          mb: { xs: 1, md: 6 },
          mt: { xs: 2, md: 4 },
          fontFamily: "Montserrat",
          fontSize: { xs: 14, sm: 18, md: 22 },
          fontWeight: 600,
        }}
      >
        {t("car_deposit")}: 100€
      </Typography>

      <PriceTable data={data.tablePrice} />
      <Box sx={{ mb: 3 }}>
        <Calculator data={data.tablePrice} />
      </Box>
      <RentForm />
      <ImageModalView
        open={open}
        handleToggle={handleToggle}
        LeftArrow={LeftArrow}
        data={data}
        RightArrow={RightArrow}
        imageNumber={imageNumber}
        CircleButtons={CircleButtons}
      />
    </Box>
  );
};

export default RentCarDetails;
